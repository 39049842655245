import React, { FC, useEffect, useRef, useState } from 'react';
import { Parallax } from 'react-scroll-parallax';
import AnimatedImage from '../../../components/AnimatedImage/AnimatedImage';
import Button from '../../../components/Button/Button';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';

import './CaseListItem.scss';

gsap.registerPlugin(ScrollTrigger);

export type CaseListItemProps = {
    id: string;
    name: string | JSX.Element;
    field: string;
    description: string;
    image: string;
    isImageRight: boolean;
    link: string;
    isActive?: boolean;
};

const CaseListItem = ({
    name,
    field,
    description,
    image,
    isImageRight,
    id,
    link,
    isActive = false,
}: CaseListItemProps) => {
    const [isUnderView, setIsUnderView] = useState(isActive);
    const caseListItemRef = useRef<HTMLDivElement>(null);

    const handelScrollTrigger = (startViewport: number, startTrigger: number) => {
        ScrollTrigger.create({
            trigger: caseListItemRef.current,
            start: `${startTrigger} ${startViewport}`,
            end: `${startTrigger} ${startViewport}`,
            onEnter: () => {
                caseListItemRef.current?.classList.add('animation-play');
            },
            once: true,
        });
    };

    useEffect(() => {
        if (!caseListItemRef.current) return;

        const startViewport = (window.innerHeight * 3) / 4;
        const startTrigger = -(caseListItemRef.current?.clientHeight * 0.6);

        gsap.timeline({
            scrollTrigger: {
                trigger: `.case-list-item--${id}`,
                start: `${startTrigger} ${startViewport}`,
                scrub: 1,
                onEnter: () => {
                    setIsUnderView(true);
                },
            },
        });

        handelScrollTrigger(startViewport, startTrigger);
    }, []);

    useEffect(() => {
        if (isActive) {
            setIsUnderView(true);
            caseListItemRef.current?.classList.add('animation-play');
        }
    }, [isActive]);

    return (
        <div
            className={`case-list-item case-list-item--${id} animated fadeInUp`}
            ref={caseListItemRef}>
            <div
                className={`case-list-item__wrapper case-list-item__wrapper${
                    isImageRight ? '--right-side-image' : ''
                }`}>
                <Parallax speed={-7} className="case-list-item__parallax">
                    <div
                        className={`case-list-item__media case-list-item__media${
                            isImageRight ? '--right-side-image' : ''
                        }`}>
                        <AnimatedImage
                            imageAlt="Case image"
                            imageUrl={image}
                            isActive={isUnderView}
                        />
                    </div>
                </Parallax>
                <div className="case-list-item__content">
                    <h2 className="case-list-item__name">
                        {typeof name === 'string' ? (
                            <>
                                {name}
                                <br />/
                            </>
                        ) : (
                            name
                        )}
                    </h2>
                    <p className="case-list-item__field">{field}</p>
                    <p className="case-list-item__description">{description}</p>
                    <Button className="case-list-item__button" tag="link" href={link}>
                        View Project
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CaseListItem;
