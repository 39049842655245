import React, { useContext, useEffect } from 'react';
import { AppContext, AppContextProps } from '../store/context';
import { CASE_STUDIES_HERO } from '../mock/SiteData';

import SimpleHero from '../sections/SimpleHero/SimpleHero';
import CaseStudyList from '../sections/CaseStudyList/CaseStudyList';
import SEO from '../components/SearchEngineOptimizations/SearchEngineOptimizations';

import 'normalize.css';

const { title, heroDescriptionText, bgImg } = CASE_STUDIES_HERO;

const ProjectsPage = () => {
    const { setCurrentPage } = useContext(AppContext) as AppContextProps;

    useEffect(() => {
        setCurrentPage('Case Studies');
    }, []);

    return (
        <main className="page page--case-studies">
            <SimpleHero
                descriptionText={heroDescriptionText}
                title={title}
                bgImg={bgImg}
                paralaxTranslateY={[20, -20]}
            />
            <CaseStudyList />
        </main>
    );
};

export default ProjectsPage;

export const Head = () => (
    <SEO title="Loop3 Studio - Case Studies" description={`${heroDescriptionText}`} />
);
