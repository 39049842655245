import React, { FC } from 'react';
import { ParallaxBanner, EffectNumber } from 'react-scroll-parallax';
import Button from '../../components/Button/Button';

import './SimpleHero.scss';

export type SimpleHeroProps = {
    title: string;
    descriptionText: string | JSX.Element;
    descriptionLight?: boolean;
    imgSrc?: string;
    imgAlt?: string;
    bgImg: string;
    pageDescription?: string;
    paralaxTranslateY?: EffectNumber;
};

const SimpleHero = ({
    title,
    descriptionText,
    descriptionLight,
    imgSrc,
    imgAlt,
    bgImg,
    pageDescription,
    paralaxTranslateY,
}: SimpleHeroProps) => {
    return (
        <section className="simple-hero">
            <div className="simple-hero__background" aria-hidden="true">
                <ParallaxBanner
                    layers={[
                        {
                            image: bgImg,
                            speed: -15,
                            scale: [1.1, 1.3, 'easeInOut'],
                            expanded: false,
                            translateY: paralaxTranslateY,
                        },
                    ]}
                    className="simple-hero__background-image"
                />
            </div>
            <div className="simple-hero__wrapper">
                <div className="simple-hero__content">
                    {pageDescription && (
                        <span className="simple-hero__page-description">{pageDescription}</span>
                    )}
                    <h1 className="simple-hero__headline">
                        {title}
                        <br />
                        <span className="simple-hero__headline-cursor">/</span>
                    </h1>
                    <p
                        className={`simple-hero__body ${
                            descriptionLight ? 'simple-hero__body--light' : ''
                        }`}>
                        {descriptionText}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default SimpleHero;
