import React, { useContext, useRef } from 'react';
import { AppContext } from '../../store/context';
import CaseListItem from './CasePage/CaseListItem';
import useTriggerLightTheme from '../../hooks/useTriggerLightTheme';
import { CaseStudyData } from '../../mock/SiteData';

import './CaseStudyList.scss';

const CaseStudyList = () => {
    const { navbarHeight } = useContext(AppContext);
    const caseStudyRef = useRef<HTMLDivElement>(null);

    const animationOffset = navbarHeight / 2 + 10;
    useTriggerLightTheme(caseStudyRef.current, animationOffset, animationOffset);

    return (
        <div ref={caseStudyRef} className="case-study-list">
            <div className="case-study-list__wrapper">
                {CaseStudyData.map((element, index) => {
                    let imageRight = false;
                    const caseNumber = index + 1;
                    if (caseNumber % 2 !== 0) {
                        imageRight = true;
                    }
                    return (
                        <CaseListItem
                            key={element.id}
                            id={element.id}
                            isActive={index === 0}
                            name={element.name}
                            field={element.field}
                            description={element.description}
                            image={element.image}
                            isImageRight={imageRight}
                            link={element.link}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default CaseStudyList;
